<template>
  <v-card
    class="mt-4"
  >
    <v-card-title style="color: var(--v-accent-base) !important">
      {{ $t('ListOfItemsYouBorrow') }}
    </v-card-title>
    <v-list
      subheader
      three-line
    >
      <v-subheader>
        {{ $t('ItemsNeedToBeReturnedToday') }}
      </v-subheader>

      <v-card-text
        v-if="!itemReturn.length"
      >
        {{ $t('NoData') }}
      </v-card-text>

      <ShareBoxItem
        v-for="item in itemReturn"
        :key="item.id_item"
        :item="item"
      />

      <v-divider></v-divider>

      <v-subheader>
        {{ $t('ListOfItemsYouBorrow') }}
      </v-subheader>

      <v-card-text
        v-if="!getItems.length"
      >
        {{ $t('NoData') }}
      </v-card-text>

      <div
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="isBusy"
      >
        <v-list
          three-line
        >
          <ShareBoxItem
            v-for="item in getItems"
            :key="item.id_item"
            :item="item"
          />
        </v-list>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { ITEM_STATUS } from '@/utils/shareBoxHelper'
import ShareBoxItem from '../components/ShareBoxItem.vue'

export default {
  components: { ShareBoxItem },
  data() {
    return {
      icons: {
        mdiInformationOutline,
      },
      itemReturn: [],
      page: 1,
      limit: 10,
    }
  },
  computed: {
    ...mapGetters('sharebox', ['getLoading', 'getIsMore', 'getItems']),
    ...mapGetters('auth', ['currentUser']),
    paramsListItem() {
      return {
        page: this.page,
        limit: this.limit,
        status: ITEM_STATUS.BORROWED,
        id_borrow: this.currentUser.id_user,
      }
    },
    isBusy() {
      return this.getLoading || !this.getIsMore || !this.getItems.length
    },
  },
  created() {
    this.handleGetData()
    this.fetchListItemReturnToDay({
      id_borrow: this.currentUser.id_user,
    }).then(data => {
      const currentDate = new Date()
      this.$set(
        this,
        'itemReturn',
        data.filter(item => this.isToday(currentDate, new Date(item.return_at))),
      )
    })
  },
  beforeDestroy() {
    this.resetStore()
  },
  methods: {
    ...mapActions('sharebox', ['fetchListItem', 'loadMoreListItem', 'resetStore', 'fetchListItemReturnToDay']),
    handleGetData: _.debounce(function () {
      this.fetchListItem({ ...this.paramsListItem, page: 1 })
    }, 300),
    loadMore() {
      this.loadMoreListItem({ ...this.paramsListItem, page: this.page + 1 }).then(() => {
        this.page += 1
      })
    },
  },
}
</script>
